@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');

:root {
  --rt-opacity: 1;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #010107;
  color: #ffffff;
  height: 100;
  overflow: hidden;
}

img {
  width: 100%;
  height: 100%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b9ffe;
}



@keyframes starglow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes blured {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}




/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .d-world-top-1-1:hover+.bottom-text-content-css {
    display: none;
  }
  .bottom-text-content-css {
    position: absolute;
    font-size: 1.5vh;
    bottom: 4vh;
    font-family: "Anta", sans-serif;
    font-weight: 200;
    left: 0px;
  }

  .bottom-text-content-css .fw-bold {
    font-size: 1.6vh;
  }

  @keyframes orbit {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(-360deg);
    }
  }

  @keyframes invert {
    0% {
      transform: rotateX(-90deg) rotateY(360deg) rotateZ(0deg);
    }

    100% {
      transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  .d-world-top-1-1:hover .d-world-top-3-3333 {
    width: 40vh;
    height: 40vh;
    top: 27vh;
    left: 25vh;
  }

  .d-world-top-1-1:hover .d-world-top-2-2222 {
    width: 50vh;
    height: 50vh;
    top: 22vh;
    left: 20vh;
    flex-direction: row !important
  }

  .d-world-top-2-2222 {
    flex-direction: column !important;
  }

  .d-world-top-1-1 {
    transform-style: preserve-3d;
    transform: rotateX(75deg);
    /* width: fit-content;
  margin: auto; */
    transition: transform 0.5s;
    width: 90vh;
    height: 90vh;
    margin: auto;
    position: relative;
    left: 3vh;
    border-radius: 50%;
  }


  .d-w-overrall-top-section {
    transform: scale(1.3);
    width: 90vh;
    margin: auto;
    position: relative;
    transition: 1.4s;
  }

  /* .d-w-overrall-top-section:hover {
    transform: scale(1);
  } */

  .d-world-top-1-1:hover {
    transform: rotateX(0deg);
  }

  .d-world-top-1-1:hover .d-world-top-1111 {
    width: 75vh;
    height: 75vh;
    top: 10vh;
    left: 7vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .d-world-top-1-1:hover .PropelX-tooltip {
    left: -10vh !important;
    top: -10vh !important;
  }

  .d-world-top-1-1:hover .dnft-tooltip {
    left: 4vh !important;
    top: 4vh !important;
  }

  .d-world-top-1-1:hover .dusd-tooltip {
    left: 2vh !important;
    top: -4vh !important;
  }

  .d-world-top-1-1:hover .DChain-tooltip {
    top: 1vh !important;
    left: -10vh !important;
  }

  .d-world-top-1-1:hover .d-world-top-4 {
    transform: rotateX(0deg);
  }

  .d-world-top-1-1:hover .d-world-top-3 {
    opacity: 1 !important;
  }

  .d-world-top-1-1:hover .d-world-top-1 .pos {
    top: -1vh !important;
  }

  .d-world-top-1-1:hover .pos {
    animation-name: none;
    /* bottom: 10vh; */
    transform-style: initial;
  }

  .d-world-top-1-1:hover .d-world-top-3, .d-world-top-1-1:hover .d-world-top-2, .d-world-top-1-1:hover .d-world-top-1, .d-world-top-1-1:hover .d-world-top-0, .d-world-top-1-1:hover .d-world-top-0-0 {
    animation-name: none;
    transform-style: initial;
  }

  .pos {
    position: relative;
    transform-style: preserve-3d;
    animation-name: invert;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* top: -4vh; */
  }

  .d-world-top-3 .pos {
    top: -3vh;
  }

  .d-world-top-0 {
    width: 90vh;
    height: 90vh;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 2vh;
    left: 0vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .d-world-top-1 {
    width: 70vh;
    height: 70vh;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 12vh;
    left: 10vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* z-index: 10; */
  }

  .d-world-top-2 {
    width: 50vh;
    height: 50vh;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 22vh;
    left: 20vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .d-world-top-3 {
    width: 45vh;
    height: 45vh;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 26vh;
    left: 22vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .d-world-top-4 {
    width: 20vh;
    height: 20vh;
    border: 1px solid #ffffff00;
    border-radius: 50%;
    position: absolute;
    top: 36vh;
    left: 35vh;
    z-index: -9;
    transform: rotateX(-75deg);
  }

  .d-world-top-1-image-1 {
    width: 9vh;
    height: 9vh;
  }

  .d-world-top-1-image-2 {
    width: 7vh;
    height: 7vh;
  }

  .d-world-top-1-image-3 {
    width: 6vh;
    height: 6vh;
  }

  .d-world-top-1-image-1-2 {
    width: 5vh;
    height: 5vh;
    position: relative;
    /* top: 1vh; */
    transition: 1s;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
  }


  .d-world-top-1-image-1-active {
    opacity: 1;
    z-index: 888;
    position: relative;
    transform: translate(0px, 0px);
    transition: 1s !important;
    pointer-events: all;
  }

  .d-world-top-1-image-1-active:hover {
    transform: scale(1.4);
  }

  .d-world-top-0 {
    transform: rotate(-90deg);
  }

  .d-world-top-0 .pos {
    transform: rotate(90deg);
  }

  .d-world-top-1 {
    transform: rotate(140deg);
  }

  .d-world-top-1 .pos {
    transform: rotate(-140deg);
  }

  .d-world-top-2 {
    transform: rotate(120deg);
  }

  .d-world-top-2 .pos {
    transform: rotate(-120deg);
  }

  .d-world-top-2-2222 {
    transform: rotate(-120deg);
  }

  .d-world-top-2-2222 .pos {
    transform: rotate(120deg);
  }

  .d-world-top-1-image-2, .d-world-top-1-image-3 {
    transition: 0.8s;
  }

  .d-world-top-1-image-2:hover, .d-world-top-1-image-3:hover {
    transform: scale(1.4);
  }

  .banner-video-1 {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -999;
    opacity: 0.6;
  }

  .d-world-text-1 {
    font-family: "Anta", sans-serif;
    font-size: 3.6vh;
    text-align: center;
    position: absolute;
    top: 6vh;
    z-index: -99;
    margin-bottom: 0px;
    opacity: 1;
    width: 80%;
    left: 10%;
    font-weight: 600;
    transition: 1s;
    animation-name: blured;
    animation-iteration-count: 1;
    animation-duration: 0.4s;
    /* opacity: 0.9; */
    /* background: linear-gradient(161deg, #579CDA 0%, #F490A7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    text-shadow: 4px 8px 15px #e93f74;
  }

  .d-world-text-1-active {
    width: 25%;
    left: 2%;
    top: 6vh;
    font-size: 2.8vh;
    transition: 0.6s;
    animation-name: blured;
    animation-iteration-count: 1;
    animation-duration: 1.3s;
  }

  .floating-start-1 {
    width: 100px;
    height: 100px;
    position: fixed;
    left: 4%;
    top: 40%;
    animation-name: starglow;
    animation-iteration-count: infinite;
    animation-duration: 3.5s;
    opacity: 0.7;
  }

  .floating-start-2 {
    width: 80px;
    height: 80px;
    position: fixed;
    left: 14%;
    top: 84%;
    animation-name: starglow;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    opacity: 0.7;
  }

  .floating-start-3 {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 4%;
    top: 60%;
    animation-name: starglow;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    opacity: 0.7;
  }

  .floating-start-4 {
    width: 80px;
    height: 80px;
    position: fixed;
    right: 4%;
    top: 20%;
    opacity: 0.7;
  }
}

/* =========================================== MOBILE-VIEW =================================== */

@media (max-width: 991px) {
  .bottom-text-content-css {
    display: none;
  }

  .d-world-top-3-3333 {
    align-items: end !important;
    justify-content: space-around !important;
  }


  /* @keyframes orbit {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(-360deg);
    }
  }

  @keyframes invert {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  } */

  .d-world-top-1-1 {
    transform-style: preserve-3d;
    /* width: fit-content;
  margin: auto; */
    transition: transform 0.5s;
    width: 76vh;
    height: 76vh;
    margin: auto;
    position: relative;
    left: -36vh;
  }

  .d-w-overrall-top-section {
    width: 76vh;
    margin: auto;
    position: relative;
    transition: 1.4s;
  }

  .d-world-top-1-1 .pos {
    transform: rotate(-14deg);
  }

  .d-world-top-2 .pos {
    transform: rotate(0deg);
  }

  .d-world-top-1-1 .PropelX-tooltip {
    left: -6vh !important;
    top: 0vh !important;
  }

  .d-world-top-1-1 .dnft-tooltip {
    left: 6vh !important;
    top: 2vh !important;
  }

  .d-world-top-1-1 .dusd-tooltip {
    left: -4vh !important;
    top: -1vh !important;
  }

  .d-world-top-1-1 .dcx-tooltip {
    left: 0vh !important;
  }

  .d-world-top-1-1 .dwallet-tooltip {
    top: -2vh !important;
    left: 0vh !important;
  }

  .d-world-top-1-1 .DChain-tooltip {
    left: -5vh !important;
    top: -2vh !important;
  }

  .d-world-top-1-1 .d-world-top-3 {
    opacity: 1 !important;
  }

  .d-world-top-1-1 .Getx-tooltip {
    left: -7vh !important;
  }

  .d-world-top-1-1 .validator-tooltip {
    left: -7vh !important;
  }

  .d-world-top-1-1:hover .pos {
    animation-name: none;
    /* bottom: 10vh; */
    transform-style: initial;
  }

  .d-world-top-1-1:hover .d-world-top-3, .d-world-top-1-1:hover .d-world-top-2, .d-world-top-1-1:hover .d-world-top-1, .d-world-top-1-1:hover .d-world-top-0, .d-world-top-1-1:hover .d-world-top-0-0 {
    animation-name: none;
    transform-style: initial;
  }

  .pos {
    position: relative;
    transform-style: preserve-3d;
    animation-name: invert;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* top: -4vh; */
  }

  .d-world-top-3 .pos {
    top: -3vh;
    transform: rotate(0deg);
  }

  .d-world-top-0 .pos {
    top: -4vh;
    left: 0vh;
  }

  .d-world-top-0 .lottery-tooltip {
    left: -9vh !important;
    top: 3vh !important;
  }

  .d-world-top-0 .promote-tooltip {
    top: 4vh !important;
    left: 3vh !important;
  }

  .d-world-top-0 .prediction-tooltip {
    left: -3vh !important;
    top: 6vh !important;
  }

  .d-world-top-0 .dswap-tooltip {
    left: 4vh !important;
    top: 4vh !important;
  }

  .d-world-top-0 .CrosschainSwap-tooltip {
    left: 3vh !important;
    top: -8vh !important;
  }

  .d-world-top-0 {
    width: 76vh;
    height: 76vh;
    border: 1px solid #ffffff77;
    border-radius: 50%;
    position: absolute;
    top: 12vh;
    left: 0vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: end;
  }

  .d-world-top-1 {
    width: 58vh;
    height: 58vh;
    border: 1px solid #ffffff77;
    border-radius: 50%;
    position: absolute;
    top: 21vh;
    left: 10vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: end;
    /* z-index: 10; */
    transform: rotate(14deg);
  }

  .d-world-top-2 {
    width: 38vh;
    height: 38vh;
    border: 1px solid #ffffff77;
    border-radius: 50%;
    position: absolute;
    top: 31vh;
    left: 20vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .d-world-top-3 {
    width: 32vh;
    height: 32vh;
    border: 1px solid #ffffff77;
    border-radius: 50%;
    position: absolute;
    top: 34vh;
    left: 22vh;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
  }

  .d-world-top-4 {
    width: 16vh;
    height: 16vh;
    border: 1px solid #ffffff00;
    border-radius: 50%;
    position: absolute;
    top: 42vh;
    left: 36vh;
    z-index: -9;
  }

  .d-world-top-1-image-1 {
    width: 9vh;
    height: 9vh;
  }

  .d-world-top-1-image-2 {
    width: 6vh;
    height: 6vh;
  }

  .d-world-top-1-image-3 {
    width: 5vh;
    height: 5vh;
    position: relative;
    top: 1vh;
  }

  .d-world-top-1-image-1-2 {
    width: 5vh;
    height: 5vh;
    position: relative;
    /* top: 1vh; */
    transition: 1s;
    z-index: 999;
    opacity: 0;
    pointer-events: none;
  }


  .d-world-top-1-image-1-active {
    opacity: 1;
    z-index: 888;
    position: relative;
    transform: translate(0px, 0px);
    transition: 1s !important;
    pointer-events: all;
  }

  .d-world-top-1-image-1-active:hover {
    transform: scale(1.4);
  }

  .d-world-top-0 {
    transform: rotate(-90deg);
  }

  .d-world-top-0 .pos {
    transform: rotate(90deg);
  }


  .d-world-top-2-2222 {
    transform: rotate(-70deg);
  }

  .d-world-top-2-2222 .pos {
    transform: rotate(70deg);
  }

  .d-world-top-1-image-2, .d-world-top-1-image-3 {
    transition: 0.8s;
  }

  .d-world-top-1-image-2:hover, .d-world-top-1-image-3:hover {
    transform: scale(1.4);
  }

  .banner-video-1 {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -999;
    opacity: 0.6;
  }

  .d-world-text-1 {
    font-family: "Anta", sans-serif;
    font-size: 2vh;
    text-align: center;
    position: absolute;
    top: 1vh;
    z-index: -99;
    margin-bottom: 0px;
    opacity: 1;
    width: 90%;
    left: 5%;
    font-weight: 600;
    transition: 1s;
    animation-name: blured;
    animation-iteration-count: 1;
    animation-duration: 0.4s;
    text-align: end;
    /* opacity: 0.9; */
    /* background: linear-gradient(161deg, #579CDA 0%, #F490A7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    text-shadow: 4px 8px 15px #e93f74;
  }


  .floating-start-1 {
    width: 50px;
    height: 50px;
    position: fixed;
    left: 4%;
    top: 40%;
    animation-name: starglow;
    animation-iteration-count: infinite;
    animation-duration: 3.5s;
    opacity: 0.7;
  }

  .floating-start-2 {
    width: 40px;
    height: 40px;
    position: fixed;
    left: 14%;
    top: 84%;
    animation-name: starglow;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    opacity: 0.7;
  }

  .floating-start-3 {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 4%;
    top: 60%;
    animation-name: starglow;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    opacity: 0.7;
  }

  .floating-start-4 {
    width: 30px;
    height: 30px;
    position: fixed;
    right: 4%;
    top: 20%;
    opacity: 0.7;
  }
}